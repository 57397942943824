<template>
    <div class="page-cnt w-full h-full bg-white">
        <div class="header w-full">
            <span v-for="n in 3" :key="n" :class="`item-${n}`" />
        </div>

        <Image
            src="layout/images/duon_logo_azul.svg"
            alt="Logo formando a palavra Duon"
            width="132"
            class="logo flex justify-content-center align-items-center"
        />

        <div class="body w-full md:h-full flex align-items-start md:align-items-center md:grid">
            <section class="hidden h-full md:block md:col-6 illustration-section"></section>
            <section class="login-section flex align-items-center">
                <div class="login-cnt flex flex-column md:justify-content-center gap-5">
                    <span class="title md:text-4xl">{{ tittle }}</span>
                    <span class="subtitle">Para entrar por favor preencha os campos com seu <b>CNPJ</b> e com a <b>chave</b> enviada por e-mail</span>
                    <span class="w-full flex flex-column gap-5">
                        <span class="w-full flex flex-column">
                            <label class="label text-bluegray-500" for="cnpj"> CNPJ: </label>
                            <InputMask
                                id="cnpj"
                                mask="99.999.999/9999-99"
                                autocomplete="off"
                                class="w-full p-4"
                                :class="{ 'p-invalid': v$.cnpj.$invalid }"
                                v-model.trim="cnpj"
                            />
                            <small v-if="v$.cnpj.$invalid || v$.cnpj.$pending.$response" class="p-error w-full">{{
                                v$.cnpj.required.$message
                            }}</small>
                        </span>
                        <span class="w-full flex flex-column">
                            <label class="label text-bluegray-500" for="senha"> Chave: </label>
                            <Password
                                :feedback="false"
                                toggleMask
                                id="senha"
                                inputClass="w-full p-4 p-input-icon-right"
                                :class="{ 'p-invalid': v$.senha.$invalid }"
                                v-model="senha"
                            />
                            <small v-if="v$.senha.$invalid || v$.senha.$pending.$response" class="p-error">{{ v$.senha.required.$message }}</small>
                        </span>
                    </span>
                    <Button label="Entrar" class="p-button-rounded py-3 text-2xl btn" @click="fazerLogin" :loading="loading" />
                </div>
            </section>
        </div>

        <div class="footer flex w-full justify-content-end align-items-center px-6 bg-bluegray-50">
            <span class="flex align-items-center gap-2" style="padding-right: 4px"
                >Desenvolvido por
                <Image src="layout/images/logo-dsg.svg" alt="Logo formando a palavra DSG" />
            </span>
        </div>
    </div>
    <Toast />
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { loginPrestador } from '@/services/auth';
import { useWindowSize } from '@vueuse/core/index';

export default {
    props: {
        cadastroPrestador: { type: Boolean, default: false },
        tittle: { type: String, default: 'Confirmação de consulta' }
    },
    setup() {
        const { width } = useWindowSize();

        return {
            v$: useVuelidate(),
            width
        };
    },
    data() {
        return {
            loading: false,
            cnpj: '',
            senha: ''
        };
    },
    validations() {
        return {
            cnpj: { required: helpers.withMessage('CNPJ deve ser informado', required), $lazy: true },
            senha: { required: helpers.withMessage('Senha deve ser informada', required), $lazy: true }
        };
    },
    computed: {
        isMobile() {
            return this.width < 576;
        },
        display() {
            return this.isMobile ? '' : 'grid';
        },
        logoWidth() {
            return this.isMobile ? '100%' : '';
        },
        padding() {
            return this.isMobile ? '0 10%' : '0 20%';
        },
        gridColumns() {
            return this.isMobile ? '4px 90px 1fr 36px' : '60px 1fr 101px';
        },
        position() {
            return this.isMobile ? 'initial' : 'absolute';
        },
        titleSize() {
            return this.isMobile ? '22px' : '35px';
        }
    },
    methods: {
        async fazerLogin() {
            const formularioCorreto = await this.v$.$validate();
            if (!formularioCorreto) return;
            try {
                this.loading = true;
                await loginPrestador(this.cnpj, this.senha, this.cadastroPrestador);
                await this.$router.replace({ path: `${this.cadastroPrestador ? '/cadastro-prestador/cadastro' : '/confirmacao-prestador/inicio'}` });
            } catch (err) {
                const mensagem = err?.response?.data?.message ?? 'Usuário ou senha inválidos!';
                this.loading = false;
                this.$toast.add({
                    severity: 'error',
                    summary: mensagem,
                    life: 3000
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$rosa-claro: #3ba7e3;
$rosa-medio: #364de7;
$rosa-primario: #3f51b5;

@font-face {
    font-family: Poppins-Regular;
    src: url('../../../public/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('../../../public/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../../../public/fonts/poppins/Poppins-Medium.ttf');
}

.page-cnt {
    display: grid;
    box-sizing: border-box;
    min-height: 100vh;
    grid-template-rows: v-bind(gridColumns);
    font-family: Poppins-Regular, sans-serif;
    position: relative;

    .header {
        height: 4px;
        display: grid;
        grid-template-columns: 1fr 2fr 3fr;

        .item-1 {
            background-color: $rosa-primario;
        }

        .item-2 {
            background-color: $rosa-medio;
        }

        .item-3 {
            background-color: $rosa-claro;
        }
    }

    .logo {
        width: v-bind(logoWidth);
        position: v-bind(position);
        right: 40px;
        top: 40px;
    }

    .body {
        display: v-bind(display);
        grid-template-columns: 1fr 1fr;

        .illustration-section {
            border-radius: 0 49px 49px 0;
            background-image: url('../../../public/layout/images/acesso-prestador/ilustracao-login.svg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: right;
        }

        .login-section {
            .login-cnt {
                padding: v-bind(padding);

                .title {
                    font-size: v-bind(titleSize);
                    font-family: Poppins-Medium, sans-serif;
                    font-weight: 600;
                }

                .subtitle {
                    font-size: 14px;
                }

                .label {
                    font-size: 17.5px;
                }
            }

            .btn {
                margin-top: 24px;
                background-color: $rosa-primario;
                border: none;
            }

            :deep(.pi-eye-slash:before),
            :deep(.pi-eye:before) {
                height: 100%;
                margin-right: 4px;
                font-size: 16px;
                line-height: 13px;
            }
        }
    }

    :deep(.p-input-icon-right > svg) {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        right: 0.5rem;
    }

    .footer {
        height: 38px;
        align-self: flex-end;
    }
}
</style>
