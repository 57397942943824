<template>
    <Login cadastroPrestador tittle="Cadastro de agenda" />
    <DialogFinalizacao ref="dialogFinalizacao" />
</template>

<script>
import Login from '@/components/prestador/Login.vue';
import DialogFinalizacao from '@/pages/cadastro-prestador/CadastroPrestador/components/DialogFinalizacao.vue';
export default {
    components: { Login, DialogFinalizacao },
    mounted() {
        const { finalizado } = this.$route.query;
        if (finalizado) {
            this.$refs.dialogFinalizacao.openDialog();
        }
    }
};
</script>
